/* General Styles */
.subscriptions-page {
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
    color: #333;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.subscriptions-content {
    flex: 1;
    padding: 20px 40px;
    background-color: white;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 1200px;
}

.error-message {
    color: red;
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
}

/* Table Styles */
.subscriptions-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.subscriptions-table th,
.subscriptions-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 14px;
    word-wrap: break-word;
}

.subscriptions-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.subscriptions-table tbody tr {
    background-color: #f8f9fa;
    transition: background 0.3s ease-in-out;
}

.subscriptions-table tbody tr:nth-child(even) {
    background-color: #e9ecef;
}

.subscriptions-table tbody tr:hover {
    background-color: #d6d6d6;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.pagination button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination .active-page {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Back to Cabinet Button */
.back-cabinet-btn {
    display: block;
    margin: 20px auto;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.back-cabinet-btn:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .subscriptions-content {
        padding: 15px;
    }

    .subscriptions-table th,
    .subscriptions-table td {
        font-size: 10px;
        padding: 2px;
    }

    .pagination button {
        padding: 6px 12px;
        font-size: 12px;
    }

    .back-cabinet-btn {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 520px) {
    .subscriptions-content {
        padding: 10px;
    }

    .subscriptions-table th,
    .subscriptions-table td {
        font-size: 6px;
        padding: 2px;
    }

    .pagination button {
        padding: 4px 8px;
        font-size: 10px;
    }

    .back-cabinet-btn {
        padding: 6px 12px;
        font-size: 12px;
    }
}
