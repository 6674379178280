.services-page {
    background-color: #f8f9fa;
    text-align: center;
}
/* Intro Section for Basic Services */
.services-intro-page {
    background-color: white;
    padding: 40px 20px;
}

.intro-content {
    max-width: 800px;
    margin: auto;
}

.intro-content h2 {
    font-size: 28px;
    margin-bottom: 20px;
}

.intro-content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
}

/* Services List Section */
/*.services-list {*/
/*    background-color: white;*/
/*    padding: 60px 20px;*/
/*}*/

.services-table-container {
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
}

.services-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.services-table th, .services-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.services-table th {
    background-color: #007bff;
    color: white;
    font-size: 16px;
}

.services-table td {
    background-color: #f8f9fa;
    font-size: 14px;
}

.services-table tr:nth-child(even) td {
    background-color: #e9ecef;
}

.services-table tr:hover td {
    background-color: #d6d6d6;
}

/* Back Button */
.back-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Table Styling */
.service-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.service-table th, .service-table td {
    border: 1px solid #ddd;
    padding: 10px 15px;
    text-align: center;
}

.service-table th {
    background-color: #007BFF;
    color: #ffffff;
    font-size: 1.2rem;
}

.service-table td {
    font-size: 1rem;
    color: #333;
}

.service-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.service-table tr:hover {
    background-color: #e9f5ff;
}
/* Responsive Design: Small screens (e.g., tablets and mobile) */
@media (max-width: 520px) {
    .service-table th, .service-table td {
        padding: 2px;   /* Slightly reduced padding for better fit */
        font-size: 10px; /* Smaller text size on mobile */
    }

    .service-table {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
@media (max-width: 320px) {
    .service-table th, .service-table td {
        padding: 2px;   /* Slightly reduced padding for better fit */
        font-size: 7px; /* Smaller text size on mobile */
    }

}


/* Responsive Design */
@media (max-width: 768px) {

    .services-intro-page h2 {
        font-size: 24px;
    }

    .services-intro-page p {
        font-size: 16px;
    }


    .services-table th, .services-table td {
        padding: 8px;
        font-size: 12px;
    }

    .back-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}

/* Mobile Optimizations */
@media (max-width: 480px) {

    .services-intro-page h2 {
        font-size: 20px;
    }

    .services-intro-page p {
        font-size: 14px;
    }


    .back-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}


